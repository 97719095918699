import React from 'react';
import newcomers from '../assets/newcomers.png';
import jobseeker from '../assets/jobseeker.png';
import employer from '../assets/employer.png';
import FadeIn from 'react-fade-in';

function About() {
  return (
    <FadeIn delay={100} transitionDuration={1000} className="scroll-smooth">
      <section className="text-gray-600 body-font">
        {/* Mission */}
        <div className="container mx-auto flex px-5 pt-24 pb-12 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={newcomers}
            />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 underline underline-offset-4 decoration-primary">
              Our Mission
            </h1>

            <p className="mb-8 text-lg font-semibold leading-relaxed">
              Actively connecting refugees to their next opportunity in Canada.
            </p>

            <p>
              {' '}
              At RefugeeJobleads, we collaborate with trusted employers to
              uncover opportunities tailored for refugees in Canada. We
              understand that relocating to a new country is a significant
              transition, and searching for your first job can be overwhelming.
              Our mission is clear: to simplify the process and alleviate the
              stress of job hunting for refugees, ensuring they find the right
              fit with ease.{' '}
            </p>
          </div>
        </div>

        {/* For Job Seekers */}
        <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 underline underline-offset-4 decoration-primary">
              For Job Seekers
            </h1>

            <p className="mb-8 text-lg font-semibold leading-relaxed">
              Quality sourced jobs from verified employers.
            </p>

            <p>
              Our aim is to connect you swiftly with your first job opportunity
              in Canada and kickstart your professional journey in your new
              home. We specialize in sourcing jobs and opportunities
              specifically tailored for refugees in Canada because we believe
              everyone deserves the chance to thrive. Job searching can bring
              about anxiety and uncertainty, but we're here to alleviate that
              stress. We diligently gather job listings from verified employers,
              giving you peace of mind knowing your application will be
              considered. Start your search today and discover your perfect
              professional fit. Good luck on your journey!
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={jobseeker}
            />
          </div>
        </div>

        {/* For Employers */}
        <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={employer}
            />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 underline underline-offset-8 decoration-primary">
              For Employers
            </h1>

            <p className="mb-8 text-lg font-semibold leading-relaxed">
              "Tap into a diverse pool of talented refugees ready to contribute
              to your team's success."
            </p>

            <p>
              {' '}
              Canada's worker shortage issues are causing businesses to curtail
              operations, reduce hours and in some cases shut down completely.
              We want to help which is why we created Refugeejobleads, to find
              you talented workers who are very often overlooked, Refugees. Post
              your jobs today and find your next employee of the quarter. We
              make it really easy for your future prospect employees to apply
              which ensures that you receive lots of quality applications. Start
              today!
            </p>
          </div>
        </div>
      </section>
    </FadeIn>
  );
}

export default About;
