import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LocationMarkerIcon } from '@heroicons/react/outline';

import {
  VancouverQuick,
  SurreyQuick,
  MontrealQuick,
  EdmontonQuick,
  CalgaryQuick,
  TorontoQuick,
} from '../utils/Data';
import { client } from '../client';

const City = ({ city, numRoles }) => {
  return (
    <div className="xl:w-1/3 md:w-1/2 p-2 cursor-pointer">
      <Link to={`/jobs/city/${city}`}>
        <div className="border border-gray-200 p-4 rounded-lg">
          <div className="w-8 h-8 inline-flex items-center justify-center rounded-full p-1 bg-orange-100 text-primary mb-4">
            <LocationMarkerIcon />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            {city}
          </h2>
          <p className="leading-relaxed text-base">
            Number of roles available:{' '}
            <span className="font-bold">{numRoles}</span>
          </p>
        </div>
      </Link>
    </div>
  );
};

function Cities() {
  const [rolesSurrey, setRolesSurrey] = useState('');
  const [rolesVancouver, setRolesVancouver] = useState('');
  const [rolesEdmonton, setRolesEdmonton] = useState('');
  const [rolesCalgary, setRolesCalgary] = useState('');
  const [rolesMontreal, setRolesMontreal] = useState('');
  const [rolesToronto, setRolesToronto] = useState('');

  useEffect(() => {
    client
      .fetch(SurreyQuick)
      .then((num) => {
        setRolesSurrey(num);
      })
      .catch((err) => console.log(err));

    client
      .fetch(VancouverQuick)
      .then((num) => {
        setRolesVancouver(num);
      })
      .catch((err) => console.log(err));

    client
      .fetch(EdmontonQuick)
      .then((num) => {
        setRolesEdmonton(num);
      })
      .catch((err) => console.log(err));

    client
      .fetch(CalgaryQuick)
      .then((num) => {
        setRolesCalgary(num);
      })
      .catch((err) => console.log(err));

    client
      .fetch(MontrealQuick)
      .then((num) => {
        setRolesMontreal(num);
      })
      .catch((err) => console.log(err));

    client
      .fetch(TorontoQuick)
      .then((num) => {
        setRolesToronto(num);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
            Search By Major Cities
          </h1>
          <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
            Your next job is waiting for you!
          </p>
        </div>
        <div className="flex flex-wrap justify-center  md:-m-4">
          <City city="Vancouver" numRoles={rolesVancouver} />
          <City city="Surrey" numRoles={rolesSurrey} />
          <City city="Calgary" numRoles={rolesCalgary} />
          <City city="Edmonton" numRoles={rolesEdmonton} />
          <City city="Montreal" numRoles={rolesMontreal} />
          <City city="Toronto" numRoles={rolesToronto} />
        </div>

        <Link to="/jobs">
          <button className="flex mx-auto mt-16 text-white font-[Poppins] bg-primary px-6 py-1 rounded-lg border-2 border-primary hover:text-primary hover:bg-white ease-in duration-150 text-lg">
            See All
          </button>
        </Link>
      </div>
    </section>
  );
}

export default Cities;
