import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import FadeIn from 'react-fade-in/lib/FadeIn';

export default function Terms() {
  return (
    <FadeIn>
      <div className="w-full px-4 pt-16 ">
        <h1 className="text-2xl font-bold text-gray-900 sm:px-4 md:px-8">
          Terms & Conditions
        </h1>
        <div className="w-full sm:px-4 md:px-8 py-12 mx-auto bg-white rounded-2xl">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>1. Use of Services</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <p>
                    By using the Services, you acknowledge that we do not have
                    control over the quality, accuracy, completeness, veracity
                    or legality of content provided by third parties. In
                    addition, you acknowledge that we do not have control over
                    the integrity, responsibility or actions of Job Seekers or
                    Clients.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    A. Eligibility
                  </h2>

                  <p className="mt-2">
                    You must be at least eighteen (18) years of age or the age
                    of majority in the jurisdiction in which you reside to use
                    the Services, so that you can form a binding contract with
                    Refugeejobleads. If you are under the age of eighteen (18)
                    or the age of majority, and you are permitted to work in the
                    jurisdiction in which you reside, you represent that a
                    parent or legal guardian has reviewed and agrees to this
                    Agreement on your behalf. You may not use the Services if
                    your use of the Services has been previously terminated or
                    suspended by Refugeejobleads, unless we have provided you
                    with specific written authorization to re-use the Services.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    B. Contact By Telephone or SMS
                  </h2>

                  <p className="mt-2">
                    When using specific Services, we may ask for your consent to
                    contact you by telephone, including by SMS. By granting such
                    consent, you authorize Refugeejobleads to contact you by
                    telephone at the number(s) you have provided, and
                    acknowledge that Refugeejobleads may do so using an
                    automatic telephone dialing system or an artificial or
                    prerecorded voice (to the extent permitted by the laws of
                    the jurisdiction where you reside).
                  </p>
                  <p className="mt-2">
                    You may revoke consent to be contacted by telephone by
                    emailing unsubscribe@Refugeejobleads.com and including the
                    wording "Revocation of Telephone Consent" in the subject
                    line. To stop receiving SMS messages from Refugeejobleads,
                    you may reply “STOP” to any SMS message you receive. Consent
                    to being contacted by telephone is not required as a
                    condition of using the Services or of purchasing any other
                    property, goods, or services from Refugeejobleads.
                  </p>
                  <p className="mt-2">
                    You agree that we may, but are not obligated to, monitor or
                    record any of your telephone conversations with us for
                    quality control purposes, for training our employees and for
                    our own protection. You acknowledge that not all telephone
                    lines or calls are recorded by us and that we do not
                    guarantee that recordings of any particular telephone calls
                    will be retained or are capable of being retrieved.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    C. Contact By Email
                  </h2>
                  <p className="mt-2">
                    By providing Refugeejobleads your email address, you consent
                    to our using the email address to send you Service-related
                    and other non-commercial notices, including any notices
                    required by law, in lieu of communication by postal mail. We
                    may also use your email address to send you other messages,
                    such as changes to features of the Service and special
                    offers (to the extent permitted by the laws of the
                    jurisdiction where you reside). If you have consented to
                    receive email job alerts or marketing communications from
                    us, we will send you such communications until you opt-out.
                    If you do not want to receive job alert emails and/or
                    marketing communications from us, you may opt-out or change
                    your preferences in your Account page or by following the
                    opt-out and/or unsubscribe instructions in the email
                    message, or by requesting to be opted-out by emailing:
                    unsubscribe@Refugeejobleads.com. Please note that opting out
                    may prevent you from receiving email messages regarding
                    updates, improvements, or offers.
                  </p>
                  <p className="mt-2">
                    Please note, that while you can opt-out of marketing
                    messages and email job alerts, you cannot opt-out of
                    service-related communications, including those related to
                    security, legal notices, your account, your use of our
                    Services, billing, and other transactional purposes unless
                    you deactivate your account and stop using our Services.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    D. General Rules
                  </h2>
                  <p className="mt-2">
                    You agree not to engage in any of the following prohibited
                    activities: (i) copying, distributing, or disclosing any
                    part of the Services in any medium, including without
                    limitation by any automated or non-automated "scraping";
                    (ii) using any automated system, including without
                    limitation "robots," "spiders," "offline readers," etc., to
                    access the Services in a manner that sends more request
                    messages to the Refugeejobleads servers than a human can
                    reasonably produce in the same period of time by using a
                    conventional on-line web browser (except that
                    Refugeejobleads grants the operators of public search
                    engines revocable permission to use spiders to copy
                    materials from Refugeejobleads.com for the sole purpose of,
                    and solely to the extent necessary for, creating publicly
                    available searchable indices of the materials, but not
                    caches or archives of such materials); (iii) transmitting
                    spam, chain letters, or other unsolicited communications;
                    (iv) attempting to interfere with, compromise the system
                    integrity or security or decipher any transmissions to or
                    from the servers running the Services; (v) taking any action
                    that imposes, or may impose, as determined in our sole
                    discretion, an unreasonable or disproportionately large load
                    on our infrastructure; (vi) uploading invalid data, viruses,
                    worms, or other software agents through the Services; (vii)
                    collecting or harvesting any personally identifiable
                    information from the Services, except as expressly permitted
                    by certain features of the Services; (viii) using the
                    Services for any commercial solicitation purposes; (ix)
                    impersonating another person or otherwise misrepresenting
                    your affiliation with a person or entity, conducting fraud,
                    hiding or attempting to hide your identity; (x) interfering
                    with the proper working of the Services; (xi) accessing any
                    content on the Services through any technology or means
                    other than those provided or authorized by the Services;
                    (xii) bypassing the measures we may use to prevent or
                    restrict access to the Services, including without
                    limitation, features that prevent or restrict use or copying
                    of any content or enforce limitations on use of the Services
                    or the content therein; (xiii) disclosing or sharing login
                    credentials; (xiv) reverse engineering or decompiling any
                    parts of the Services; (xv) frame or link to any information
                    or content on the Services; (xvi) post or submit any
                    inaccurate, incomplete, or false biographical information or
                    another person’s information; or (xvi) post or submit any
                    material that is unlawful, illegal, defamatory, offensive,
                    discriminatory, threatening, or obscene as determined by
                    Refugeejobleads.
                  </p>
                  <p className="mt-2">
                    We may, without prior notice, change any aspect of the
                    Services; stop providing the Services or features of the
                    Services; or create usage limits for the Services (for paid
                    products and services). If any such change, cessation or
                    limitation of the Services materially and adversely affects
                    you, then you may terminate the Services on written notice
                    to us. In the event of such termination, you will receive a
                    pro-rata refund of any pre-paid fees pertaining to Services
                    paid for, but not provided, for the balance of the
                    applicable term of your then-current subscription plan. We
                    may permanently or temporarily limit, condition, terminate
                    or suspend your access to the Services or any features
                    thereof, including if in our sole determination (acting
                    reasonably) you breach or violate any provision of this
                    Agreement, commit fraud or other abuse using the Services.
                  </p>
                  <p className="mt-2">
                    You are solely responsible for your interactions with other
                    Refugeejobleads Users. We reserve the right, but have no
                    obligation, to monitor disputes between you and other Users.
                    Refugeejobleads shall have no liability for your
                    interactions with other Users, or for any User's action or
                    inaction. Refugeejobleads shall have no obligation to you to
                    enforce this Agreement against any other User.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-4 flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>2. User Content and Users</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <h2 className="mt-2 font-bold text-gray-900">
                    A. Posting or viewing user content
                  </h2>

                  <p className="mt-2">
                    The Services may allow Users to post and/or provide content
                    that may be viewable by other Users, including, but not
                    limited to, Job Advertisements, screening requirements,
                    screening questions, screening criteria, company
                    information, a job seeker’s application information, content
                    of messages, resumes/CVs, logos, trademarks, comments,
                    questions, and other content or information ("User
                    Content").
                  </p>

                  <p className="mt-2">
                    User Content is the sole responsibility of the person or
                    entity that provided the User Content. You shall be solely
                    responsible for your User Content and the consequences of
                    posting, providing or publishing it.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    B. Prohibited Types of User Content
                  </h2>

                  <p className="mt-2">
                    The following list is intended to be illustrative of the
                    types of User Content that are prohibited, but this is not
                    an exhaustive list: You agree not to post or provide User
                    Content that (i) may create a risk of harm to any person or
                    property; (ii) provides instructional information about
                    illegal activities; (iii) involves the transmission of junk
                    or unsolicited mail or other communications, ‘phishing’ or
                    ‘scamming’; (iv) provides links to material that is illegal
                    or offensive, or targets or solicits personal information
                    from anyone under the age of 18; (v) contains any
                    information or content that we deem to be unlawful, harmful,
                    abusive, racially or ethnically offensive, defamatory,
                    infringing, invasive of personal privacy or publicity
                    rights, harassing, humiliating to other people (publicly or
                    otherwise), libelous, threatening, profane, or otherwise
                    objectionable; (vi) contains any information or content that
                    is illegal (including, without limitation, the disclosure of
                    insider information under securities law or of another
                    party's trade secrets); (vii) contains any information or
                    content that you do not have a right to make available under
                    any law or under contractual or fiduciary relationships;
                    (viii) contains any information or content that is false,
                    misleading, or otherwise deceptive; or (ix) violates the
                    Intellectual Property Rights (as defined below) or rights of
                    privacy of any third party. For the purposes of this
                    Agreement, "Intellectual Property Rights" means patent
                    rights, copyright rights, mask work rights, moral rights,
                    rights of publicity, trademark, trade dress and service mark
                    rights, goodwill, trade secret rights and other intellectual
                    property rights as may now exist or hereafter come into
                    existence, and all applications therefore and registrations,
                    renewals and extensions thereof, under the laws of any
                    state, country, territory or other jurisdiction. If you are
                    a Client providing or submitting Job Advertisements, you
                    further agree that such content will not contain any
                    personally identifiable information.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    C. No Warranties or Obligations
                  </h2>
                  <p className="mt-2">
                    You agree that Refugeejobleads makes no representations,
                    warranties, promises or guarantees regarding any content
                    provided or generated by third parties, including, without
                    limitation, the content of messages, Job Advertisements and
                    resumés. You further agree that Refugeejobleads acts as a
                    passive conduit for the distribution, provision, and
                    publication of User Content, and has no obligation to screen
                    or verify the accuracy, legality, legitimacy, truthfulness,
                    or completeness of User Content, and accordingly, you accept
                    that Refugeejobleads is not responsible and has no liability
                    for User Content. You understand that you may be exposed to
                    User Content that is inaccurate, incomplete, illegal,
                    misleading, false, offensive, constitutes spam, or is
                    otherwise unsuited to your purpose, and you accept that it
                    is your responsibility to verify the quality, accuracy,
                    truthfulness, legality or reliability of User Content,
                    including, without limitation, resumes/CVs and Job
                    Advertisements and content of messages. Your reliance on any
                    User Content is at your own risk.
                  </p>
                  <p className="mt-2">
                    Notwithstanding the foregoing, although Refugeejobleads has
                    no obligation to screen User Content, to the extent that
                    Refugeejobleads becomes or is made aware of User Content
                    that may or does (i) violate the terms of this Agreement or
                    any other Agreement you have with Refugeejobleads, or (ii)
                    violate any law or regulation, or (iii) violate the rights
                    of third parties, or (iv) create liability for
                    Refugeejobleads or otherwise negatively impact
                    Refugeejobleads, Refugeejobleads reserves the right to
                    reject and/or remove such User Content, and suspend and/or
                    terminate any User Account associated with such User
                    Content.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    D. User Interactions and Assumption of Risk
                  </h2>
                  <p className="mt-2">
                    You understand and agree that Refugeejobleads does not, and
                    cannot, confirm that each User is who he/she/they claim to
                    be. We are not responsible for authenticating Users and
                    therefore it is your responsibility to conduct the
                    appropriate due diligence before communicating or
                    interacting with other Users, including, without limitation,
                    Job Seekers, employers, staffing agencies, and recruiters.
                    You assume all risks associated with Users with whom you
                    come into contact. If you have any disputes or issues with
                    any User you agree to pursue any remedies directly with the
                    applicable User and you release Refugeejobleads, its
                    subsidiaries, and their respective employees, directors and
                    agents from all claims, demands and damages of every kind
                    (actual and consequential and direct and indirect) known and
                    unknown, suspected and unsuspected, disclosed and
                    undisclosed, to the fullest extent permitted by law.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    E. Content Disclosure
                  </h2>
                  <p className="mt-2">
                    Refugeejobleads reserves the right to disclose User Content
                    and any information related to the provider of such User
                    Content, to third parties in connection with the operation
                    and provision of the Services, to enforce the terms of any
                    agreement that we have with you, to comply with legal
                    obligations and requests from governmental authorities, law
                    enforcement agencies, court orders, subpoenas, etc., and to
                    protect the interests of Refugeejobleads where necessary.
                    For the avoidance of doubt, you agree that we have the right
                    to disclose your identity to a third party who is claiming
                    that any User Content you posted, provided or uploaded is
                    fraudulent, false, or misleading or constitutes a violation
                    of the law, or a violation of their intellectual property or
                    ownership rights, or of their right to privacy.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-4 flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>3. User Content License Grant</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <p className="mt-2">
                    By posting, submitting, providing and/or otherwise making
                    available any User Content, you expressly grant, and
                    represent that you have a right to grant, to
                    Refugeejobleads, a royalty-free, sublicensable (through
                    multiple layers of sublicensees), perpetual, transferable,
                    non-exclusive, worldwide license to use, sell, reproduce,
                    adapt, translate, sublicense, publicly perform, publicly
                    display, and make derivative works from and otherwise
                    exploit, all such User Content in whole or in part, and in
                    any form, media or technology, whether now known or
                    hereafter developed, for the purposes of posting such User
                    Content on the Refugeejobleads websites, distributing such
                    User Content through the Refugeejobleads distribution and
                    publishing network and in job alerts to job seekers, and to
                    promote Refugeejobleads and the Services. This license
                    continues even if you stop using the Services.
                  </p>

                  <p className="mt-2">
                    If you are a Client, you acknowledge and agree that
                    Refugeejobleads has no obligation, and may be unable, to
                    remove your Job Advertisements and other content (including
                    any logos and/or trademarks contained therein) once they
                    have been (i) distributed via the Refugeejobleads
                    distribution and publisher network, (ii) listed in search
                    engine results, or (iii) distributed on websites and in job
                    alert emails or job alert texts, or (iv) incorporated into
                    fixed media displays of Refugeejobleads or any third party,
                    and in each case (i) to (iv) above, the license grant from
                    you above will be perpetual and irrevocable for such uses.
                    To the extent your User Content is a trademark, trade name,
                    service mark or service name protected by law, the foregoing
                    license also extends to the use by Refugeejobleads to
                    identify you as a User of the Services in any promotional
                    and marketing materials to promote Refugeejobleads and the
                    Services. Further, to the extent you have given
                    Refugeejobleads the right to access certain User Content
                    that is present on another website or service you own or
                    control, you give Refugeejobleads the right to scrape such
                    website as required to retrieve such User Content for use on
                    the Services as contemplated in the license grants above,
                    notwithstanding any prohibition on scraping set forth in the
                    terms of use or other contractual terms governing such
                    website.
                  </p>

                  <p>
                    You represent and warrant that you have the rights, power
                    and authority necessary to grant the rights described in
                    this section to User Content that you submit, provide, make
                    available or post, via the Services, that the use by
                    Refugeejobleads of your User Content will not violate any
                    law or infringe the rights of any third party, and that your
                    User Content and any other information that you provide to
                    us is legal, complete, legitimate, truthful and accurate.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-4 flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>4. Our Proprietary Rights</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <p className="mt-2">
                    This Agreement provides only a limited license to access and
                    use the Services in accordance with the terms of this
                    Agreement. Accordingly, you hereby agree that
                    Refugeejobleads transfers no ownership or intellectual
                    property interest or title in and to the Services or any
                    materials used in connection with the Services, including,
                    without limitation, software, (including source code),
                    programs, products, information, documentation, images,
                    text, graphics, user interfaces, visual interfaces,
                    illustrations, logos, patents, trademarks, service marks,
                    copyrights, photographs, audio, videos, music, and content
                    (the, "Refugeejobleads Content"). All such Refugeejobleads
                    Content as well as the design, structure, selection,
                    coordination, expression, “look and feel,” and arrangement
                    of any content contained on or available through the
                    Services (except for Feedback) are exclusively owned,
                    controlled, and/or licensed by Refugeejobleads or its
                    members, parent(s) licensors, or affiliates.
                    Refugeejobleads, the Refugeejobleads logo, and all other
                    marks are proprietary trademarks of Refugeejobleads and any
                    use of such marks, including, without limitation, as domain
                    names, account identifiers, or in connection with any search
                    engine optimization practice(s), without the prior written
                    permission of Refugeejobleads is hereby strictly prohibited.
                    Except as explicitly provided herein, nothing in this
                    Agreement shall be deemed to create a license in or under,
                    any such Intellectual Property Rights, and you agree not to
                    sell, license, rent, modify, distribute, copy, reproduce,
                    transmit, publicly display, publicly perform, publish,
                    adapt, edit or create derivative works from any materials or
                    content accessible on the Services. Use of the
                    Refugeejobleads Content or materials on the Services for any
                    purpose not expressly permitted by this Agreement is
                    strictly prohibited.
                  </p>

                  <p className="mt-2">
                    If you participate in any evaluation regarding the Services
                    or otherwise provide Refugeejobleads suggestions, comments,
                    ideas, opinions, recommendations for the modification,
                    correction, improvement, or enhancement of the Services, or
                    other feedback regarding the Services (collectively,
                    "Feedback"). Such Feedback will be deemed to include a
                    royalty-free, perpetual, irrevocable, transferable,
                    sublicensable, and non-exclusive right and license for
                    Refugeejobleads to adopt, publish, reproduce, disseminate,
                    transmit, distribute, copy, use, create derivative works of,
                    and display (in whole or in part) worldwide, or act on any
                    such Feedback without additional approval or consideration
                    in any form, media, or technology now known or later
                    developed for the full term of any rights that may exist in
                    such content, and you hereby waive any claim to the
                    contrary. To the extent permitted by applicable law, you
                    waive any moral rights and irrevocably consent to any acts
                    that would otherwise infringe your moral rights, in your
                    Feedback. You agree not to provide any Feedback that is
                    false or misleading or that breaches any agreement you have
                    with a third party.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-4 flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>5. Paid Services for Clients</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <h2 className="mt-2 font-bold text-gray-900">
                    A. Billing Policies
                  </h2>

                  <p className="mt-2">
                    Certain aspects of the Services may be provided for a fee.
                    If you are a Client and elect to use a paid feature of the
                    Services, you agree to the pricing and payment terms for the
                    applicable Services, as we may update them from time to
                    time. Refugeejobleads may (1) add new services and products
                    for additional fees and charges, at any time in its sole
                    discretion, or (2) amend fees and charges for existing
                    services, at any time in its sole discretion upon notice.
                  </p>

                  <p className="mt-2">
                    If you reasonably dispute any portion of an invoice, you
                    must pay the undisputed portion of the invoice and submit
                    written notice of your dispute (with sufficient detail of
                    the nature of the dispute, the amount and invoices in
                    dispute and information necessary to identify the affected
                    Service(s)) for the disputed amount.In the event that the
                    dispute is resolved against you, you shall pay such amounts
                    plus interest at the rate referenced in Section 6(C) below.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    B. No Refunds for Paid Services
                  </h2>

                  <p className="mt-2">
                    You understand and accept that, to the fullest extent
                    available under applicable law, you will not receive a
                    refund of any pre-paid fees if you cancel, terminate, or
                    decide to no longer use of the paid service, prior to the
                    expiration of those services (for example, if you hire a
                    candidate prior to the expiration of your job posting). In
                    the event that Refugeejobleads suspends, limits, conditions,
                    or terminates your access to the Services and/or your
                    account due to your breach of this Agreement or any other
                    agreement that you have with Refugeejobleads, you understand
                    and accept that you will not receive a refund for any unused
                    time with respect to fees that you have pre-paid for a
                    product or service, and to the extent that you have not paid
                    the applicable fees, you will remain liable to pay
                    Refugeejobleads the entire fees due for the product or
                    service, as applicable.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    C. Payment Information; Taxes; Delinquent Payments
                  </h2>
                  <p className="mt-2">
                    All information that you provide in connection with a
                    monetary transaction must be accurate, complete, and
                    current. You agree to pay all charges incurred by users of
                    your credit card, debit card, or other payment method used
                    in connection with a purchase or transaction at the prices
                    in effect when such charges are incurred. You will also pay
                    any applicable taxes relating to such transactions. If at
                    any time Refugeejobleads is required by a taxing authority
                    to pay any taxes not previously collected from you, you will
                    promptly submit such taxes (including applicable penalties
                    and interest, if any) to Refugeejobleads upon written
                    notice.If you elect to enhance your job posting with certain
                    upgrades, we will invoice you for such upgrades and/or
                    charge your credit or debit card (or other payment method)
                    accordingly.
                  </p>
                  <p className="mt-2">
                    Delinquent payments may bear interest at the rate of
                    one-and-one-half percent per month (or the highest rate
                    permitted by law, if less) from the payment due date until
                    paid in full. You will be responsible for all reasonable
                    expenses (including legal fees and expenses) incurred by
                    Refugeejobleads in collecting delinquent amounts, except
                    where the delinquent amounts are due to billing
                    inaccuracies. In addition, if you are late in payment of any
                    fees, we reserve the right to either (i) immediately suspend
                    or terminate your access to the applicable Services without
                    notice, and in the event of any such suspension or
                    termination, you will remain responsible for the entire
                    amount of fees owing for such Services; or (ii) bill your
                    credit/debit card on file with us under this or any other
                    agreement. Unless your subscription plan is terminated
                    timely and correctly, you will continue to accrue charges
                    even if you don’t use the subscribed Services.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    D. User Interactions and Assumption of Risk
                  </h2>
                  <p className="mt-2">
                    You understand and agree that Refugeejobleads does not, and
                    cannot, confirm that each User is who he/she/they claim to
                    be. We are not responsible for authenticating Users and
                    therefore it is your responsibility to conduct the
                    appropriate due diligence before communicating or
                    interacting with other Users, including, without limitation,
                    Job Seekers, employers, staffing agencies, and recruiters.
                    You assume all risks associated with Users with whom you
                    come into contact. If you have any disputes or issues with
                    any User you agree to pursue any remedies directly with the
                    applicable User and you release Refugeejobleads, its
                    subsidiaries, and their respective employees, directors and
                    agents from all claims, demands and damages of every kind
                    (actual and consequential and direct and indirect) known and
                    unknown, suspected and unsuspected, disclosed and
                    undisclosed, to the fullest extent permitted by law.
                  </p>

                  <h2 className="mt-2 font-bold text-gray-900">
                    E. Content Disclosure
                  </h2>
                  <p className="mt-2">
                    Refugeejobleads reserves the right to disclose User Content
                    and any information related to the provider of such User
                    Content, to third parties in connection with the operation
                    and provision of the Services, to enforce the terms of any
                    agreement that we have with you, to comply with legal
                    obligations and requests from governmental authorities, law
                    enforcement agencies, court orders, subpoenas, etc., and to
                    protect the interests of Refugeejobleads where necessary.
                    For the avoidance of doubt, you agree that we have the right
                    to disclose your identity to a third party who is claiming
                    that any User Content you posted, provided or uploaded is
                    fraudulent, false, or misleading or constitutes a violation
                    of the law, or a violation of their intellectual property or
                    ownership rights, or of their right to privacy.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-4 flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>6. Job Seekers</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <p className="mt-2">
                    The purpose of the Website is to connect refugee status
                    holders and immigrant job seekers (“Job Seekers”) with
                    Canadian employers (“Employers”). You may only access or use
                    the profile, resume upload, and job application features of
                    the Job Seekers Page to respond to Employers about
                    employment opportunities, which the Employers have posted on
                    the Website. You agree that the information contained in
                    your profile, resume and job application accurately describe
                    your experience, education and identity and are not
                    exaggerated, incorrect or untruthful. The Company reserves
                    the right to edit or remove any profile, resume or job
                    application which is not in accordance with the Terms
                    without prior notice. The Company reserves the right to deny
                    in our sole discretion any person from accessing the Website
                    or any portion thereof without prior notice. The Company
                    makes no warranties or representations regarding the
                    truthfulness, accuracy, correctness, reliability or
                    otherwise with respect to the information contained on this
                    Website.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-4 flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>7. Employers</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <p className="mt-2">
                    With respect to all Job Advertisements that you submit, make
                    available, provide, post or distribute, whether on your own
                    behalf or on behalf of a third party, in addition to the
                    other requirements in this Agreement, you represent and
                    warrant that (i) the content of Job Advertisements (whether
                    owned by you or your clients), will comply with advertising
                    standards and applicable laws, including, but not limited
                    to, employment and privacy laws, in your jurisdiction and
                    the jurisdictions in which the vacancies are located; (ii)
                    you have the necessary rights to permit the publication and
                    use of Job Advertisements by Refugeejobleads pursuant to
                    this Agreement; (iii) the use, reproduction, distribution or
                    transmission of Job Advertisements will not violate any
                    applicable laws or any rights of any third parties,
                    including, but not limited to, infringement of any
                    copyright, trademark, or other proprietary right, false
                    advertising, defamation, any other right of any person or
                    entity; (iv) the content of Job Advertisements shall comply
                    with the then-current versions of this Agreement and Job
                    Posting Rules (referenced below); and (v), you have the
                    authority to grant permission to Refugeejobleads to wrap or
                    collect Job Advertisements from applicable websites if
                    necessary, to include in distributions and any such wrapping
                    will not cause Refugeejobleads to violate the rights of any
                    third party. You acknowledge and agree that you are solely
                    responsible for any liability arising out of publication of
                    Job Advertisements or material to which users can link
                    though such Job Advertisements. You hereby indemnify and
                    hold Refugeejobleads and its subsidiaries, and their
                    respective officers, directors, employees, and agents
                    harmless from and against any and all claims, costs,
                    damages, losses, liabilities and expenses (including
                    attorneys' fees and costs) arising out of or in connection
                    with Job Advertisements provided by you or any other content
                    provided by you, or your breach of this Agreement.
                  </p>

                  <p className="mt-2">
                    You agree not to post or promote any Job Advertisements
                    that: (i) contain inaccurate, false, or misleading
                    information; (ii) contain "hidden" keywords or keywords that
                    are irrelevant to the job opportunity being presented; (iii)
                    sell, promote or advertise products or services; (iv)
                    endorse a particular political party, political agenda, or
                    political position or promote a particular religion; (v)
                    advertise job openings located in countries subject to
                    economic sanctions of the European Union, except where
                    permitted by applicable law; (vi) require the applicant to
                    provide information relating to his/her (a) racial or ethnic
                    origin, (b) political beliefs, (c) religious beliefs, (d)
                    membership of a trade union, (e) physical or mental health,
                    (f) sexual life, (g) the commission of criminal offences or
                    proceedings, or (h) age; (vii) contain content or links to
                    content that exploit people in a sexual, violent or other
                    manner, or solicits personal information from anyone under
                    the age of 13; (viii) includes any screening requirement or
                    criteria where such requirement or criteria is not a bona
                    fide occupational requirement for the role; (ix) involves
                    any franchise, pyramid scheme, "club membership",
                    distributorship, or multi-level marketing opportunity or
                    requires recruitment of other members, sub-distributors or
                    sub-agents; (x)requires applicants to pay to apply, pay for
                    training, pay for training materials, or pay for samples;
                    (xi) contain content that violates applicable laws.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-4 flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>8. Limitation of liability</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <p className="mt-2">
                    In no event shall the Company and its affiliates or any of
                    their respective directors, officers, employees, agents,
                    content providers, other representatives, successors and
                    assigns be liable for any direct, indirect, special,
                    incidental, consequential, punitive, or aggravated damages
                    (including without limitation damages for loss of data,
                    income or profit, loss of or damage to property, third party
                    claims, disclosure of confidential information or loss of
                    privacy) or any other damages of any kind, arising out of or
                    in connection with: the Website, any information, forms,
                    agreements, and documents available through the Website, any
                    termination of access to the Website and the services; any
                    software tips, tools, products or services offered through
                    or advertised on the Website; any content, materials, act or
                    practice of any third party provider; any link provided on
                    the Website; any trojan horses, bugs, viruses and the like;
                    deletion, corruption or failure to store any data; your
                    failure to provide correct, accurate and up-to-date
                    information; and your account and password or your failure
                    to keep them secure, whether or not the Company has been
                    advised of the possibility of such damages. This exclusion
                    of liability shall apply to the fullest extent permitted by
                    the law. This provision shall survive the termination of
                    your right to use this Website. You acknowledge and agree
                    that you will be fully liable for all damages resulting
                    directly or indirectly from your use of the Website.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-4 flex justify-between w-full px-4 py-2 text-lg font-semibold text-left text-primary bg-orange-50 rounded-lg hover:bg-orange-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>9. Indemnification</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-700">
                  <p className="mt-2">
                    You agree to indemnify and hold harmless the Company and its
                    affiliates and their respective directors, officers,
                    employees, agents, content providers, other representatives,
                    successors and assigns from and against all claims,
                    liabilities, losses, damages, suits, actions, costs and
                    expenses (including legal expenses) relating to or arising
                    out of your use of the Website including the transmission or
                    placement of information or material by you on the Website.
                    This provision shall survive the termination of your right
                    to use this Website.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </FadeIn>
  );
}
