//HomePage --Active
export const homePageJobsQuery = `*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true] | order(postedOn desc) 
 {
     _id, title, salary, city, postedOn, categories
 }[0...10]

 `;

export const jobsPageQuery = (currentPage) => {
  const query = `*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true] | order(postedOn desc) 
 {
     _id, title, salary, city, postedOn, categories
 }[${currentPage * 10}...${currentPage * 10 + 10}]
 `;
  return query;
};

//Jobview --Active
export const jobViewQuery = (jobId) => {
  const query = `*[_type=='post' && _id == '${jobId}'] 
      {
           _id,title,email,salary,city,location,categories,body,postedOn,postExpiry,companyName,companyBio,vacancies
      }
      `;

  return query;
};

//cityJobView
export const cityJobs = (city, currentPage) => {
  const query = `*[_type=='post' &&     && city == '${city}' && isActive == true] 
      {
        _id, title, salary, city, postedOn, categories
      }[${currentPage * 10}...${currentPage * 10 + 10}]
      `;

  return query;
};

//Search Query
export const searchQuery = (title, location, currentPage) => {
  const query = `*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true &&  ((city match '${location}' || province match '${location}' ) && (title match '${title}' || body match '${title}'))] 
      {
        _id, title, salary, city, postedOn, categories
      }[${currentPage * 10}...${currentPage * 10 + 10}]
      `;

  return query;
};

export const titleQuery = (title, currentPage) => {
  const query = `*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true && (title match '${title}' || body match '${title}')] 
      {
        _id, title, salary, city, postedOn, categories
      }[${currentPage * 10}...${currentPage * 10 + 10}]
      `;

  return query;
};

export const locationQuery = (location, currentPage) => {
  const query = `*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true && (city match '${location}' || province match '${location}' )] 
      {
        _id, title, salary, city, postedOn, categories
      }[${currentPage * 10}...${currentPage * 10 + 10}]
      `;

  return query;
};

export const titleQueryMax = (title) => {
  return `count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true && (title match '${title}' || body match '${title}')] 
  {
    _id, title, salary, city, postedOn, categories
  })`;
};
export const locationQueryMax = (location) => {
  return `count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true && (city match '${location}' || province match '${location}' )] 
  {
    _id, title, salary, city, postedOn, categories
  })`;
};
export const searchQueryMax = (title, location) => {
  return `count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true && ((city match '${location}' || province match '${location}' ) && (title match '${title}' || body match '${title}'))] 
  {
    _id, title, salary, city, postedOn, categories
  })`;
};

// Filter Query
export const partTimeQuery = (currentPage) => {
  const query = `*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && categories =='Part-time' && isActive == true] | order(postedOn desc) 
 {
     _id, title, salary, city, postedOn, categories
 }[${currentPage * 10}...${currentPage * 10 + 10}]
 `;
  return query;
};

export const fullTimeQuery = (currentPage) => {
  const query = `*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && categories =='Full-time' && isActive == true] | order(postedOn desc) 
 {
     _id, title, salary, city, postedOn, categories
 }[${currentPage * 10}...${currentPage * 10 + 10}]
 `;
  return query;
};

//Total Openings
export const totalOpenings = `count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && isActive == true])`;

//Total fullTimeOpenings
export const totalPtOpenings = `count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && categories =='Part-time' && isActive == true])`;

//Total parttimeOpenings
export const totalFtOpenings = `count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && categories =='Full-time' && isActive == true])`;

//Fast cities --Active
export const SurreyQuick = `
  count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && city =='Surrey' && isActive == true])
 `;
export const VancouverQuick = `
  count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && city =='Vancouver' && isActive == true])
 `;
export const EdmontonQuick = `
  count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && city =='Edmonton' && isActive == true])
 `;
export const MontrealQuick = `
  count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && city =='Montreal' && isActive == true])
 `;
export const CalgaryQuick = `
  count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && city =='Calgary' && isActive == true])
 `;
export const TorontoQuick = `
  count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && city =='Toronto' && isActive == true])
 `;

export const cityMax = (city) => {
  const query = `
  count(*[_type=='post' && ("RefugeeJobLeads" in postAt[] || "NewJobLeads" in postAt[]) && city == '${city}' && isActive == true])
 `;

  return query;
};
