import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import newcomers from '../assets/newcomers1.png';

function Hero() {
  const [searchTerm, setSearchTerm] = useState('term');
  const [searchLocation, setSearchLocation] = useState('loc');

  return (
    <section className="text-gray-600 body-font">
      <div className="container  mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow lg:pr-12 md:w-2/3 md:pr-8 md:mx-8 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font text-3xl mb-4 font-medium text-gray-900">
            Ready to start your journey towards employment in Canada? Let's find
            the perfect job for you together.
          </h1>
          <p className="mb-8 leading-relaxed">
            Discover opportunities tailored for refugees in Canada with
            RefugeeJobLeads. Trusted by employers nationwide, we've linked
            countless refugees with their first job. Start your journey toward
            employment today!
          </p>
          <div className="flex md:flex-row flex-col w-full justify-between items-center md:items-end">
            <div className="relative flex md:flex-row flex-col mr-4 w-4/5 md:w-full lg:w-full items-center ">
              <input
                required
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Job title, keywords, or company"
                type="text"
                id="hero-field"
                name="hero-field"
                className="w-full bg-gray-100 rounded border bg-opacity-50 border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <input
                required
                onChange={(e) => setSearchLocation(e.target.value)}
                placeholder="City, or Province"
                type="text"
                id="hero-field"
                name="hero-field"
                className="w-full md:ml-4 md:mt-0 mt-4 bg-gray-100 rounded border bg-opacity-50 border-gray-200 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <Link to={`/search/${searchLocation}/${searchTerm}`}>
              <button className="inline-flex mt-4 md:mt-0 text-white font-[Poppins] bg-primary px-6 py-1 rounded-lg border-2 border-primary hover:text-primary hover:bg-white ease-in duration-150 text-lg">
                Search
              </button>
            </Link>
          </div>
          <p className="text-sm mt-2 font-semibold text-gray-500 mb-8 w-full">
            Job leads tailored for refugees in Canada! Discover opportunities
            crafted to support your journey.
          </p>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            draggable="false"
            alt="hero"
            src={newcomers}
          />
        </div>
      </div>
    </section>
  );
}

export default Hero;
