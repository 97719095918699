import React, { useEffect, useState } from 'react';
import {
  CalendarIcon,
  LocationMarkerIcon,
  CurrencyDollarIcon,
  BriefcaseIcon,
  ChevronLeftIcon,
  PrinterIcon,
  PaperClipIcon,
  MailIcon,
} from '@heroicons/react/outline';

import { Link, useNavigate, useParams } from 'react-router-dom';
import PortableText from 'react-portable-text';
import { jobViewQuery } from '../utils/Data';
import { client } from '../client';
import { NotFound } from '../pages';

const EmployerInfoCard = ({ name, fullAddress, email, bio }) => {
  return (
    <div className=" lg:w-1/4 sm:px-4 sm:mx-4 my-8 border border-gray-200 p-4 rounded-lg bg-gray-100">
      <p className="font-medium  mb-6 font-[Poppins] text-primary text-lg mr-12">
        Posted By:
      </p>
      <div className="flex flex-row items-center justify-between mb-2">
        <h2 className="font-bold font-[Poppins] text-gray-800 text-lg mr-12">
          {name}
        </h2>

        <a
          href={`mailto:${email}?subject=Application&body=Please send your resume and cover letter to apply`}
          className="h-6 w-6 text-gray-800 ml-12"
        >
          <MailIcon />
        </a>
      </div>
      <div className="flex mb-6 items-center justify-start">
        <LocationMarkerIcon className="h-6 w-6 text-gray-800 mr-2" />
        <p>{fullAddress}</p>
      </div>

      <PortableText
        className=""
        content={bio}
        projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
        dataset={process.env.REACT_APP_SANITY_DATASET}
        serializers={{
          h1: (props) => (
            <h1
              className="text-2xl text-gray-800 font-bold mb-2"
              {...props}
            ></h1>
          ),
          h2: (props) => (
            <h2
              className="text-xl text-gray-800 font-bold mb-2"
              {...props}
            ></h2>
          ),
          h3: (props) => (
            <h3
              className="text-xl text-gray-800 font-bold mb-2"
              {...props}
            ></h3>
          ),
          h4: (props) => (
            <h4
              className="text-lg text-gray-800 font-semibold mb-2"
              {...props}
            ></h4>
          ),
          li: (props) => <li className="list-disc my-2 ml-4" {...props}></li>,
          link: (props) => (
            <a
              className=" text-primary hover:underline cursor-pointer"
              {...props}
            ></a>
          ),
          normal: (props) => <p className="" {...props}></p>,
        }}
      />
    </div>
  );
};

function JobView() {
  const history = useNavigate();

  const [jobData, setJobData] = useState({
    _id: '',
    body: [],
    categories: '',
    vacancies: '',
    city: '',
    email: '',
    companyBio: [],
    companyName: '',
    location: '',
    salary: ' ',
    title: '',
    postedOn: '',
    postExpiry: '',
  });

  // Take params from the link and use it to fetch job details
  const { btnId } = useParams();

  const fetchJobDetails = () => {
    client
      .fetch(jobViewQuery(btnId))
      .then((data) => {
        setJobData(data[0]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchJobDetails();
  }, [btnId]);

  const expiryDate = new Date(jobData.postExpiry);
  const expiryDateCourtesy = expiryDate.setDate(expiryDate.getDate() + 1);
  const currentDate = new Date();
  const isPostExpired = expiryDateCourtesy < currentDate;

  if (!jobData) {
    return <NotFound />;
  }
  return (
    <>
      <div className="px-4 mx-4">
        <button onClick={() => history(-1)}>
          <div className="text-lg font-semibold text-gray-900 flex flex-row items-center">
            <ChevronLeftIcon className="text-gray-900 h-5 w-5" />
            <h1>Back</h1>
          </div>
        </button>

        <div>
          {/* JOB CONTENT */}
          <div className="flex flex-wrap -m-4 mt-8 ">
            <div className="w-full mb-4 ">
              <div className=" border border-gray-200 p-4 rounded-lg bg-gray-100 mb-2  md:mx-4">
                <div className="lg:flex lg:items-center lg:justify-between">
                  <div className="flex-1 min-w-0">
                    <h2 className="text-xl font-bold leading-7 text-gray-900 ">
                      {jobData.title}
                    </h2>
                    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <BriefcaseIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary"
                          aria-hidden="true"
                        />
                        {jobData.categories}
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <LocationMarkerIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary"
                          aria-hidden="true"
                        />
                        {jobData.city}
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <CurrencyDollarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary"
                          aria-hidden="true"
                        />
                        {jobData.salary}
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary"
                          aria-hidden="true"
                        />
                        Posted on: {jobData.postedOn}
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 flex lg:mt-0 lg:ml-4">
                    <span className="sm:block ">
                      <a href={`mailto:${jobData.email}`}>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-primary ease-in duration-100 hover:bg-orange-100 rounded-md shadow-sm text-sm font-medium text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                          <PaperClipIcon
                            className="-ml-1 mr-2 h-5 w-5 text-primary"
                            aria-hidden="true"
                          />
                          Apply
                        </button>
                      </a>
                    </span>

                    <span className=" sm:block ml-3">
                      <button
                        onClick={window.print}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-primary ease-in duration-100 hover:bg-orange-100 rounded-md shadow-sm text-sm font-medium text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      >
                        <PrinterIcon
                          className="-ml-1 mr-2 h-5 w-5 text-primary"
                          aria-hidden="true"
                        />
                        Print
                      </button>
                    </span>
                  </div>
                </div>
              </div>

              {/* Job Description */}
              <div className="flex flex-col lg:flex-row justify-between items-start mb-24">
                <div className=" sm:2/4 lg:w-3/5 md:mx-4 sm:px-2 sm:mx-2 mt-8 ">
                  <article>
                    <PortableText
                      className=""
                      content={jobData.body}
                      projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                      dataset={process.env.REACT_APP_SANITY_DATASET}
                      serializers={{
                        h1: (props) => (
                          <h1
                            className="text-2xl text-gray-800 font-bold mb-2"
                            {...props}
                          ></h1>
                        ),
                        h2: (props) => (
                          <h2
                            className="text-xl text-gray-800 font-bold mb-2"
                            {...props}
                          ></h2>
                        ),
                        h3: (props) => (
                          <h3
                            className="text-xl text-gray-800 font-bold mb-2"
                            {...props}
                          ></h3>
                        ),
                        h4: (props) => (
                          <h4
                            className="text-lg text-gray-800 font-semibold mb-2"
                            {...props}
                          ></h4>
                        ),
                        li: (props) => (
                          <li className="list-disc my-2 ml-4" {...props}></li>
                        ),
                        link: (props) => (
                          <a
                            className=" text-primary hover:underline cursor-pointer"
                            {...props}
                          ></a>
                        ),
                        normal: (props) => <p className="" {...props}></p>,
                      }}
                    />
                  </article>

                  <div className=" mt-8 border border-gray-200 p-4 rounded-lg bg-gray-100">
                    <p className="font-medium  font-[Poppins] text-primary text-lg mr-12">
                      Additional Job Information:
                    </p>
                    {/* Job Status  */}
                    <p className="mt-2 text-sm sm:text-base">
                      <span className="font-semibold underline underline-offset-2 font-[Poppins] text-gray-700 mr-2">
                        Job Status:
                      </span>{' '}
                      <span
                        className={`font-medium text-gray-100 py-1 px-2 rounded-full ${
                          isPostExpired ? 'bg-red-700' : 'bg-green-600'
                        }`}
                      >
                        {isPostExpired ? 'Expired' : 'Active'}
                      </span>
                    </p>

                    <p className="mt-2 text-sm sm:text-base">
                      <span className="font-semibold underline underline-offset-2 font-[Poppins] text-gray-700 mr-2">
                        Job ID:
                      </span>{' '}
                      <span className="font-medium text-gray-700">
                        RJL-{jobData._id.slice(0, 4) + jobData._id.slice(32)}
                      </span>
                    </p>
                    <p className="mt-2 text-sm sm:text-base">
                      <span className="font-semibold underline underline-offset-2 font-[Poppins] text-gray-700 mr-2">
                        Eligibility:
                      </span>{' '}
                      <span className="font-medium text-gray-700">
                        Refugee status holders in Canada
                      </span>
                    </p>
                    <p className="mt-2 text-sm sm:text-base">
                      <span className="font-semibold underline underline-offset-2 font-[Poppins] text-gray-700 mr-2">
                        Vacancies:
                      </span>{' '}
                      <span className="font-medium text-gray-700">
                        {jobData.vacancies}
                      </span>
                    </p>
                    <p className="mt-2 text-sm sm:text-base">
                      <span className="font-semibold  underline underline-offset-2 font-[Poppins] text-gray-700 mr-2">
                        Location:
                      </span>{' '}
                      <span className="font-medium  text-gray-700">
                        {jobData.location ? jobData.location : jobData.city}
                      </span>
                    </p>
                    <p className="mt-2 text-sm sm:text-base">
                      <span className="font-semibold underline underline-offset-4 font-[Poppins] text-gray-700 mr-2">
                        Expiry Date:
                      </span>{' '}
                      <span className="font-medium text-gray-700">
                        {jobData.postExpiry}
                      </span>
                    </p>
                  </div>
                </div>

                <EmployerInfoCard
                  name={jobData.companyName}
                  fullAddress={jobData.city}
                  email={jobData.email}
                  bio={jobData.companyBio}
                />
              </div>
            </div>
          </div>
        </div>

        {/* End of Job Content */}
      </div>
    </>
  );
}

export default JobView;
